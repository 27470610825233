import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const TotalAmountLeftCard = ({ totalAmountLeft }) => {
  const [displayAmount, setDisplayAmount] = useState('');

  useEffect(() => {
    if (totalAmountLeft !== '') {
      setDisplayAmount(totalAmountLeft);
    }
  }, [totalAmountLeft]);

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div">
          Remaining Budget
        </Typography>
        <Typography variant={displayAmount ? "h4" : "h6"} component="div" style={{ marginTop: '16px' }}>
          {displayAmount ? " €" + displayAmount : 'Loading...'}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TotalAmountLeftCard;
